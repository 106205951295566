<template>
  <div id="login" class="container-fluid text-start w-auto">
    <div class="text-center">
      <img
        src="@/assets/logo.svg"
        alt="impairex-logo"
        height="60"
        class="border-end border-3 px-3"
      />
      <img
        src="@/assets/svg/tpdex-logo.svg"
        class="border-end border-3 px-3"
        alt="tpdex-logo"
        height="60"
      />
      <img
        src="@/assets/svg/emrex-logo.svg"
        class="px-3"
        alt="emrex-logo"
        height="60"
      />
    </div>
    <div class="d-flex mb-5 pb-5 justify-content-between"></div>
    <h1 class="my-4">Enter your credentials</h1>

    <form class="" @submit.prevent="loginSubmit">
      <div class="col-12">
        <label for="username-input" class="form-label">Your Username</label>
        <input
          id="username-input"
          v-model="login.username"
          class="form-control-lg login-input"
          type="text"
          placeholder=""
          required
        />
      </div>

      <div class="mt-4">
        <label for="password-input" class="form-label">Password</label>
        <input
          id="password-input"
          ref="password"
          v-model="login.password"
          class="form-control-lg login-input"
          type="password"
          placeholder=""
          required
        />
        <span ref="toggleVisibility" class="d-inline-block" @click="toggleView">
          <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 0.846924C7.41454 0.846924 3.25621 3.35567 0.187788 7.43054C-0.0625959 7.76438 -0.0625959 8.23078 0.187788 8.56463C3.25621 12.6444 7.41454 15.1532 12 15.1532C16.5855 15.1532 20.7438 12.6444 23.8122 8.56954C24.0626 8.23569 24.0626 7.76929 23.8122 7.43545C20.7438 3.35567 16.5855 0.846924 12 0.846924ZM12.3289 13.0372C9.28506 13.2286 6.7714 10.7199 6.96287 7.6711C7.11998 5.15745 9.15741 3.12001 11.6711 2.96291C14.7149 2.77144 17.2286 5.28019 17.0371 8.32897C16.8751 10.8377 14.8377 12.8752 12.3289 13.0372ZM12.1767 10.7101C10.537 10.8132 9.18196 9.46306 9.28997 7.8233C9.37343 6.46828 10.4732 5.37347 11.8282 5.28509C13.4679 5.182 14.823 6.5321 14.7149 8.17187C14.6266 9.5318 13.5268 10.6266 12.1767 10.7101Z"
              fill="#C4C4C4"
            />
          </svg>
        </span>
        <div
          v-show="!isValidLogin"
          id="passwordHelpBlock"
          class="form-text text-danger"
        >
          Ops! Invalid username / password.
        </div>
      </div>

      <div class="col-12 mt-2">
        <div class="row text-start text-cyan">
          <router-link
            :to="{ name: 'forgot-password' }"
            class="forgot-password"
          >
            Forgot your password?
          </router-link>
        </div>
      </div>

      <div class="col-4 float-left mt-3">
        <BaseButton type="submit">Log in</BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { isLoggedIn } from 'axios-jwt'

import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  name: 'Login',
  components: { BaseButton },
  data() {
    return {
      isValidLogin: true,
      login: {
        username: '',
        password: '',
      },
    }
  },

  mounted() {
    if (isLoggedIn()) {
      // assume we are logged in because we have a refresh token }

      this.$router.push({ name: 'tpd-list' })
    }
  },

  methods: {
    toggleView() {
      this.$refs.toggleVisibility.classList.toggle('unhide')

      if (this.$refs.password.getAttribute('type') === 'password') {
        return this.$refs.password.setAttribute('type', 'text')
      } else {
        return this.$refs.password.setAttribute('type', 'password')
      }
    },

    async loginSubmit() {
      await this.$store
        .dispatch('login', this.login)
        .then(() => {
          this.isValidLogin = true
          this.$router.push({ name: 'tpd-list' })
        })
        .catch((error) => {
          this.isValidLogin = false
          console.error(error)
        })
    },
  },
}
</script>

<style scoped lang="scss">
h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
}

form {
  font-size: 18px;

  span {
    margin-left: -7%;
    cursor: pointer;
  }
}

label {
  font-weight: 400;
  line-height: 18px;
  color: #747474;
  display: block;
}

.login-input {
  font-weight: 500;
  width: 100%;
  &:focus {
    border: 2px solid #32c3d6;
  }
}

.forgot-password {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
}
</style>
